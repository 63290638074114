<template>
  <v-app>
    <v-main>
      <v-container class="mt-16">
        <h2>Payment method</h2>
        <h2 class="title font-weight-bold mt-12">Manage Payment</h2>
        <p class="subtitle-2">
          Manage your payment method, or Add a new Payment method
        </p>
      </v-container>
      <v-container class="mt-12">
        <v-row>
          <v-col cols="12" md="3">
            <v-card>
              <v-img
                class="white--text mx-auto"
                width="200"
                src="@/assets/Svg/wallet.svg"
              >
              </v-img>
              <v-card-title class="subtitle-1 font-weight-medium mt-2 ml-3"
                >Add payment new method</v-card-title
              >
              <v-card-text>
                <div class="caption ml-3">
                  set up your payment method to fasten up your transaction.
                </div>
              </v-card-text>
              <v-btn text class="primary--text ml-3 mb-2">
                <span class="mr-2"><v-icon>mdi-plus</v-icon></span>
                New payment method
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="12" md="9">
            <v-data-table color="#01577A"> </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      user_id: 1, // for development porpuse static muna yung id sir
      wallets: [],
      dialog: false,
    };
  },
  created() {
    this.getWallet();
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    getWallet() {
      this.axios
        .get("wallet?" + this.user_id)
        .then(({ data }) => {
          this.wallets = data.wallet[0];
        })
        .catch();
    },
  },
  computed: {
    FormatedAmount() {
      return this.wallets.filter((wallet) => {
        return wallet.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });
    },
  },
};
</script>